.p-datatable .p-sortable-column.p-highlight {
  background: var(--main-blue);
}
.page-forms-list {
  background: white;
  padding: 15px;
  .actions {
    display: flex;
    justify-content: space-around;
  }
}
