.results-container-country,
.results-container {
    h1 {
        margin-bottom: 5px;
    }

    .excerpt {
        background: white;
        font-size: .93em;
        font-weight: 400;
        line-height: 1.4;
        border: 1px solid lightgrey;
        padding: 15px;
        margin-bottom: 30px;
    }

    .csv,
    .filters {
        padding: 15px;
        border-radius: 3px;
        width: max-content;
        background: white;
        margin: 0 0 15px 0;

        h3 {
            font-size: 1em;
            font-weight: bold;
            margin-top: 0;
            flex-grow: 1;
        }
    }

    .filters {
        width: 100%;

        .filters-wrapper {
            display: flex;
        }

        .filter-input {
            display: flex;
            flex-direction: column;
            padding: 5px;

            &:first-child {
                padding-left: 0;
            }

            >label {
                font-size: 0.75em;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
    }
}

.results-container-country {
    .loading {
        width: max-content;
        margin: 15px 0;

        h3 {
            font-size: 1em;
            font-weight: bold;
            margin-top: 0;
            flex-grow: 1;
        }
    }

    .wrapper {
        padding: 15px;
        margin-bottom: 15px;
        width: max-content;

        .filter-input {
            display: flex;
            flex-direction: column;

            label {
                font-size: 0.75em;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }

        .btn {
            margin-top: 10px;

            button {
                margin-right: 5px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        h2 {
            margin-top: 0;
        }
    }
}

.results-container {
    h1 {
        border-bottom: none;
    }

    .p-buttonset.p-buttonset-3 {
        span {
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                margin-right: 6px;
            }
        }

        :nth-child(1) {
            &.p-button.p-highlight {
                border-color: #00b8df;
                background: #00b8df;

                span::before {
                    filter: invert(1);
                }
            }

            span {
                &::before {
                    background: url("../../../../images/tint-solid.svg") no-repeat;
                }
            }
        }

        :nth-child(2) {
            &.p-button.p-highlight {
                border-color: #3f9645;
                background: #3f9645;

                span::before {
                    filter: invert(1);
                }
            }

            span {
                &::before {
                    background: url("../../../../images/seedling-solid.svg") no-repeat;
                }
            }
        }
    }

    .csv {
        .p-panelmenu {
            margin-bottom: 5px;
        }

        .p-panelmenu-header.p-highlight>a {
            color: white !important;
        }
    }

    .result-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        padding-bottom: 45px;
        border-bottom: 1px dotted lightgray;
        align-items: center;

        .p-datatable {
            tfoot tr td {
                font-size: .86em;
            }
        }
    }

    .huella-agua,
    .huella-carbono {
        border-top: 1px solid lightgray;
        padding-top: 1em;

        h1 {
            text-align: center;
        }
    }

    .huella-agua {
        .results-1 {
            .p-datatable {
                table {
                    tbody {
                        tr {
                            td:nth-child(1) {
                                white-space:nowrap !important;
                                padding-right: 5em;
                            }
                            td:nth-child(2) {
                                text-align: right;
                            }
                        }
                    }
                    .p-datatable-tfoot tr td {
                        font-size: .86em;
                        text-align: center !important;
                    }
                }
                padding-bottom: 0 !important;
            }
        }

        .results-2 .p-datatable table {
            tbody {
                tr {
                    td {
                        text-align: center;
                        &:nth-child(1) {
                            text-align: left;
                        }
                    }
                }
            }
            .p-datatable-tfoot tr td {
                font-size: .86em;
                text-align: center !important;
            }
        }
    }

    .huella-carbono {
        .results-1 .p-datatable table {
            th {
                padding: 1rem 0.6rem;
                small {
                    color: #126aaa;
                    font-weight: bold;
                }
            }
            td {
                font-size: .86em;
            }

        }
    }

    .botoneraInformes {
        display: flex;
        justify-content: center;
        .botonInformeActivo {
            color: var(--main-blue);
            background-color: rgba(0,0,0,0.05);
            margin: .1em .2em;
        }
        .botonInforme {
            margin: .1em .2em;
            color: var(--text-color);
            background-color: rgba(0,0,0,0.01);
            border-color: rgba(0,0,0,0.05);
            &:hover {
                background-color: var(--gray-500);
            }
        }
        svg {
            height: 1.2em;
            width: 1.2em;
        }
    }
}

.export-buttons {
    text-align: right;
}

.sinDatos {
    text-align: center;
    span {
        color: rgb(154, 154, 154);
        font-weight: bold;
        font-size: large;
        background-color: rgb(255, 191, 191);
        padding: 10px;
        border-radius: 4px;
    }
}
