.languageSelector>div {
    background-color: rgba(255, 255, 255, 0);
    margin-right: 15px;
    border: 1px solid #007bff;
    color: #ffffff;
}

.languageSelector {
    .p-dropdown-trigger-icon {
        color: #007bff;
    }

    .p-dropdown-label {
        padding: 0 0 0 6px;
    }

    img {
        width: 32px;
    }
}
