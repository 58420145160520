@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700");

:root {
  --main-blue: #116aab;
  --secondary: #e8f0f3;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  word-wrap: break-word;
  color: #1b1d1f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
    min-width: 250px !important;
}

h1 {
  font-size: 1.85em;
  font-weight: 700;
  line-height: 1.3;
  color: #333;
  padding-bottom: 13px;
  border-bottom: #cfcfcf solid 1.1px;
  padding-bottom: 10px;
  margin: 0 0 30px;
}

h2 {
  font-size: 1.57em;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

h2:after {
  content: "";
  border-bottom: 3px solid #126aaa;
  display: block;
  width: 50px;
}

h3 {
  font-size: 1.28em;
  font-weight: 300;
}

h4 {
  margin: 0 0 0.5rem 0;
  line-height: 1.2;
  font-size: .86em;
  text-transform: uppercase;
  font-weight: 600;
}

p {
  line-height: 1.5;
  margin: 0 0 10px;
}

.p-button {
  background-color: var(--main-blue);
}

a {
  color: #126aaa !important;
  text-decoration: none;
}

a:hover {
  color: #126aa0 !important;
  /* text-decoration: underline; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* *************************** */
/*  data-title en handleLabel  */

[data-title] {
    position: relative;
}

[data-title]:hover {
    cursor: help;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    top: calc(100% + 10px);
    left: 1px;
    padding: 4px 8px 8px 8px;
    color: var(--primary-color-text);
    border-radius: 5px;
    background-color: var(--text-color-secondary);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    max-width: 30vw;
    min-width: 25vw;
    font-size: .9em;
}
/* #CCC rgb(60,60,60)
/* *************************** */

.version {
    position: fixed;
    bottom: 5px;
    left: 5px;
    font-size: small;
    color:rgba(255, 0, 0, 0.2);
    z-index: 100000;
    width: 350px;
}
.version:hover {
    font-weight: bold;
    color: red;
    font-size: large;
}

.form-container .p-dropdown .p-dropdown-label {
    width: 20px;
    text-overflow: ellipsis;
}
