.page-config {
  td.p-editable-column {
    text-align: right;
  }
  ul.label-colors {
    max-width: max-content;
    margin: 0 0 15px 0;
    padding: 15px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    li {
      font-size: .86em;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      i {
        display: block;
        border-radius: 50% 50%;
        width: 5px;
        height: 5px;
        margin-right: 5px;
      }
    }
    span {
      border-radius: 10px;
      font-size: 0.71em;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      padding: 5px;
      margin-right: 10px;
    }
    li:nth-child(1) span {
      background-color: #00b8df;
    }
    li:nth-child(2) span {
      background-color: #3f9645;
    }
  }
  ul.p-tabview-nav {
    display: flex;
    flex-wrap: wrap;
    li a {
      opacity: 0.75;
      color: white !important;
      display: flex !important;
      align-items: center;
      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        filter: invert(1);
        margin-right: 5px;
      }
    }
    li:nth-child(1) a {
      background-color: lightyellow !important;
      border-color: gold !important;
      color: black !important;
      &::before {
        filter: unset;
        background: url(../../../../images/box-open-solid.svg) no-repeat;
      }
    }
    li:nth-child(6) a,
    li:nth-child(7) a,
    li:nth-child(8) a,
    li:nth-child(9) a,
    li:nth-child(10) a,
    li:nth-child(11) a   {
      background-color: #3f9645 !important;
      border-color: #3f9645 !important;
      &::before {
        background: url(../../../../images/seedling-solid.svg) no-repeat;
      }
    }
    li:nth-child(2) a,
    li:nth-child(3) a,
    li:nth-child(4) a,
    li:nth-child(5) a {
      background-color: #00b8df !important;
      border-color: #00b8df !important;
      &::before {
        background: url(../../../../images/tint-solid.svg) no-repeat;
      }
    }
    li.p-tabview-selected {
      a {
        opacity: 1;
        text-decoration: underline;
      }
      &:nth-child(1) a {
        color: black;
        background-color: gold !important;
        border-color: gold !important;
      }
      &:nth-child(6) a,
      &:nth-child(7) a,
      &:nth-child(8) a,
      &:nth-child(9) a,
      &:nth-child(10) a,
      &:nth-child(11) a  {
        color: white !important;
        background-color: #3f9645;
        border-color: #3f9645;
        &:hover {
          opacity: 0.9;
          background-color: #3f9645;
          border-color: #3f9645;
        }
      }
      &:nth-child(2) a,
      &:nth-child(3) a,
      &:nth-child(4) a,
      &:nth-child(5) a {
        color: white !important;
        background-color: #008eca;
        border-color: #008eca;
        &:hover {
          opacity: 0.9;
          background-color: #008eca;
          border-color: #008eca;
        }
      }
    }
  }
}
