header {
    display: flex;
    background: var(--main-blue);
    padding: 20px 30px;
    width: 100%;
    z-index: 999;
    justify-content: center;

    .logo {
        display: block;
        max-width: 85%;
    }

    ul {
        display: flex;
        align-items: center;

        li {
            display: inherit;
            align-items: inherit;
            float: right;
        }
    }

    @media screen and (min-width: 520px) {
        .logo {
            max-width: 350px;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0 calc(2em + 1vw);
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 110px;

        .logo {
            max-width: 100%;
            height: 70px;
        }
    }
}
