.op-forms-grid {
  display: flex;
  flex-direction: column;
  h4 {
    margin: 30px 0 15px 0;
  }
  > ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 15px;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px solid #e8e9ea;
      a {
        display: flex;
        width: 100%;
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 2em;
          margin-bottom: 8px;
        }
        span {
          text-align: center;
        }
      }
    }
  }
  > div {
    width: max-content;
  }
}
