.elemForm {
    button.p-button {
        // background-color: var(--blue-100);
        background-color: rgba(0,0,0,0.1);
        border: 1px solid #ced4da
    }
}

.unidadConvertida {
    text-align: right;
    padding-right: 2.357rem;
}

.botonDer, .botonIzq {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #ced4da !important;
    cursor: pointer !important;
}

.tempLeyendo {
    position: absolute;
    top: 0;
    left: calc(50% - .2em);
    z-index: 1000;
}

.templateOptionSelect {
    small {
        float: right;
        color: green;
    }
}
